<template>
    <div class="row">
        <div class="col-md-12">
            <div class="box">
                <div class="box-header">
                   <h1 class="box-title pull-right mright-15" v-show="showCalendar"> <span class="pull-right calendar-toggle" v-on:click="toggleCalendar()"><i class="fa fa-list"></i></span></h1>
                   <h1 class="box-title pull-right mright-15" v-show="!showCalendar"> <span class="pull-right calendar-toggle" v-on:click="toggleCalendar()"><i class="fa fa-calendar"></i></span></h1>
                </div>
                <div class="box-body">
                    <div v-show="showCalendar">
                        <ScheduleXCalendar
                            :calendar-app="calendarApp"
                            :custom-components="customComponents"
                        >
                        </ScheduleXCalendar>
                    </div>
                    <div v-show="!showCalendar">
                        <CalendarMessageTableList> </CalendarMessageTableList>
                    </div>
                </div>


                <div class="col-md-6">
                  <div class="sx__event-form">
                    <div class="sx__event-form-alert sx__event-form-info-box">
                        <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                          <g id="SVGRepo_iconCarrier">
                              <path d="M12 16.75C11.8019 16.7474 11.6126 16.6676 11.4725 16.5275C11.3324 16.3874 11.2526 16.1981 11.25 16V11C11.25 10.8011 11.329 10.6103 11.4697 10.4697C11.6103 10.329 11.8011 10.25 12 10.25C12.1989 10.25 12.3897 10.329 12.5303 10.4697C12.671 10.6103 12.75 10.8011 12.75 11V16C12.7474 16.1981 12.6676 16.3874 12.5275 16.5275C12.3874 16.6676 12.1981 16.7474 12 16.75Z" fill="#0077cc"></path>
                              <path d="M12 9.25C11.8019 9.24741 11.6126 9.16756 11.4725 9.02747C11.3324 8.88737 11.2526 8.69811 11.25 8.5V8C11.25 7.80109 11.329 7.61032 11.4697 7.46967C11.6103 7.32902 11.8011 7.25 12 7.25C12.1989 7.25 12.3897 7.32902 12.5303 7.46967C12.671 7.61032 12.75 7.80109 12.75 8V8.5C12.7474 8.69811 12.6676 8.88737 12.5275 9.02747C12.3874 9.16756 12.1981 9.24741 12 9.25Z" fill="#0077cc"></path>
                              <path d="M12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89472 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17294C11.99 2.82567 13.7996 3.0039 15.4442 3.68509C17.0887 4.36628 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.387 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21ZM12 4.5C10.5166 4.5 9.0666 4.93987 7.83323 5.76398C6.59986 6.58809 5.63856 7.75943 5.07091 9.12988C4.50325 10.5003 4.35473 12.0083 4.64411 13.4632C4.9335 14.918 5.64781 16.2544 6.6967 17.3033C7.7456 18.3522 9.08197 19.0665 10.5368 19.3559C11.9917 19.6453 13.4997 19.4968 14.8701 18.9291C16.2406 18.3614 17.4119 17.4001 18.236 16.1668C19.0601 14.9334 19.5 13.4834 19.5 12C19.5 10.0109 18.7098 8.10323 17.3033 6.6967C15.8968 5.29018 13.9891 4.5 12 4.5Z" fill="#0077cc"></path>
                          </g>
                        </svg>
                        This event is in a hidden calendar.
                    </div>
                    <div class="sx__form-field">
                        <div class="sx__text-field-wrapper"><input id="sazzdx99x8" name="title" type="text" class="sx__text-field-input"><label for="title" class="sx__text-field-label">Title</label></div>
                    </div>
                    <div class="sx__event-time-input">
                        <div class="sx__date-time-picker sx__date-time-start">
                          <div id="sb0g5ayxzn" class="sx__date-picker-outer-wrapper">
                              <div class="sx__date-picker-wrapper">
                                <div id="snt4h205rz" class="sx__date-input-wrapper"><label for="svqp7wyz0i" id="shmge7026q" class="sx__date-input-label">Start date</label><input id="svqp7wyz0i" tabindex="0" name="start-date" aria-describedby="shmge7026q" data-testid="date-picker-input" type="text" class="sx__date-input"><button tabindex="0" aria-label="Choose Date" class="sx__date-input-chevron-wrapper"><img src="data:image/svg+xml,%3c%3fxml version='1.0' encoding='utf-8'%3f%3e%3c!-- Uploaded to: SVG Repo%2c www.svgrepo.com%2c Generator: SVG Repo Mixer Tools --%3e%3csvg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6 9L12 15L18 9' stroke='%23DED8E1' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" alt="" class="sx__date-input-chevron"></button></div>
                              </div>
                          </div>
                          <div id="s10n76nw14">
                              <div class="sx__time-picker-wrapper  ">
                                <div id="smzymv2f37" class="sx__time-input-wrapper"><label for="syur0dsg4s" class="sx__time-input-label"></label><input readonly="" id="syur0dsg4s" name="start-time" type="text" class="sx__time-picker-input"></div>
                              </div>
                          </div>
                        </div>
                        <span class="sx__date-time-until">until</span>
                        <div class="sx__date-time-picker sx__date-time-end">
                          <div id="sprxmng2ln" class="sx__date-picker-outer-wrapper">
                              <div class="sx__date-picker-wrapper">
                                <div id="si0420gwwp" class="sx__date-input-wrapper"><label for="s2bh8noeui" id="s9e1ve82kz" class="sx__date-input-label">End date</label><input id="s2bh8noeui" tabindex="0" name="end-date" aria-describedby="s9e1ve82kz" data-testid="date-picker-input" type="text" class="sx__date-input"><button tabindex="0" aria-label="Choose Date" class="sx__date-input-chevron-wrapper"><img src="data:image/svg+xml,%3c%3fxml version='1.0' encoding='utf-8'%3f%3e%3c!-- Uploaded to: SVG Repo%2c www.svgrepo.com%2c Generator: SVG Repo Mixer Tools --%3e%3csvg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6 9L12 15L18 9' stroke='%23DED8E1' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" alt="" class="sx__date-input-chevron"></button></div>
                              </div>
                          </div>
                          <div id="sj39bix2ww">
                              <div class="sx__time-picker-wrapper  ">
                                <div id="s6x8e7edcm" class="sx__time-input-wrapper"><label for="syci7d56t3" class="sx__time-input-label"></label><input readonly="" id="syci7d56t3" name="end-time" type="text" class="sx__time-picker-input"></div>
                              </div>
                          </div>
                        </div>
                    </div>
                    <label id="sz3ybh16wp" class="sx__app-checkbox"><input type="checkbox"><span>Full-day event</span></label>
                    <div class="sx__rrule-wrapper">
                        <div class="sx__form-field">
                          <div id="synloohgl4" class="sx__app-select-wrapper">
                              <div class="sx__app-select__input-wrapper"><input id="s22z3m8ril" type="text" readonly="" name="rrule" class="sx__app-select-input"><label for="rrule" class="sx__app-select-label">Frequency</label></div>
                          </div>
                        </div>
                        <div id="szcgbxqje9" class="sx__date-picker-outer-wrapper">
                          <div class="sx__date-picker-wrapper has-full-width is-disabled">
                              <div id="s4s99kvxhy" class="sx__date-input-wrapper"><label for="s92ma0xm8q" id="se7is5sj4s" class="sx__date-input-label">Until</label><input id="s92ma0xm8q" tabindex="-1" name="until" aria-describedby="se7is5sj4s" data-testid="date-picker-input" type="text" class="sx__date-input"><button tabindex="-1" aria-label="Choose Date" class="sx__date-input-chevron-wrapper"><img src="data:image/svg+xml,%3c%3fxml version='1.0' encoding='utf-8'%3f%3e%3c!-- Uploaded to: SVG Repo%2c www.svgrepo.com%2c Generator: SVG Repo Mixer Tools --%3e%3csvg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6 9L12 15L18 9' stroke='%23DED8E1' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" alt="" class="sx__date-input-chevron"></button></div>
                          </div>
                        </div>
                    </div>
                    <div class="bg-customer-reply p-3 pt-0">
                       <div class="nested-panel-heading skill-heading"><h3 class="nested-title">Recurrence Settings</h3></div>
                       <div class="form-group d-flex repeat-row">
                          <span class="fw-bold">Repeat every</span> <input type="number" step="1" max="10" value="1"  class="form-control repeat-input-70" >
                          <select class="form-control repeat-input-120">
                              <option>Month</option>
                          </select>

                          <select class="form-control" style="width:170px">
                              <option>Monthly on Day 13</option>
                              <option>Monthly on Second Monday</option>
                          </select>
                        </div>
                         
                        <div class="form-group">
                            <div class="form-lable mb-3 fw-bold">Ends</div>
                            <div class="form-check pb-4">
                                <label class="form-check-label" for="flexRadioDefault2">
                                  Never 
                                </label>
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
                            </div>
                            <div class="form-check d-flex align-items-center pb-4 col-gap-12">
                              <div class="repeat-input-70">
                                <label class="form-check-label" for="flexRadioDefault1">
                                  On
                                </label>
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                              </div>
                              <div>
                                 <input type="text" class="form-control">
                               </div>
                            </div>
                           
                            <div class="form-check d-flex align-items-center col-gap-12">
                              <div class="repeat-input-70">
                                <label class="form-check-label" for="flexRadioDefault1">
                                  After
                                </label>
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                              </div>
                              <div class="d-flex align-items-center">
                                 <input type="number" step="1" max="10" value="1" class="form-control repeat-input-70"> Occurance
                               </div>
                            </div>

                        </div>  
                        
                    </div>
                    <div class="sx__form-field">
                        <div class="sx__textarea-wrapper"><textarea id="last_name" type="text" name="description" class="sx__textarea-input" style="height: 85px;"></textarea><label for="description" class="sx__textarea-label">Description</label></div>
                    </div>
                    <div class="sx__form-field">
                        <div id="sf0f20t693" class="sx__app-combobox-wrapper">
                          <div class="sx__app-combobox__input-wrapper"><input id="shf5d9nku5" type="text" placeholder="Search..." name="people" class="sx__app-combobox-input"><label for="people" class="sx__app-combobox-label">People</label></div>
                        </div>
                    </div>
                    <div class="sx__form-field">
                        <div id="swnepfib1r" class="sx__app-combobox-wrapper">
                          <div class="sx__app-combobox__input-wrapper"><input id="s9kjl8sqel" type="text" placeholder="Search..." name="calendar" class="sx__app-combobox-input"><label for="calendar" class="sx__app-combobox-label">Calendar</label></div>
                        </div>
                    </div>
                    <div class="sx__form-field">
                        <div id="s8hpd89n8d" class="sx__app-combobox-wrapper">
                          <div class="sx__app-combobox__input-wrapper"><input id="sfew442kkv" type="text" placeholder="Search..." name="resource" class="sx__app-combobox-input"><label for="resource" class="sx__app-combobox-label">Resource</label></div>
                        </div>
                    </div>
                    <div class="sx__form-actions"><button class="sx__app-button sx__app-button-filled">Save</button><button class="sx__app-button sx__app-button-outlined">Cancel</button></div>
                  </div>

                </div>


                <div class="col-md-6">
                  <div class="sx__event-form">
                    <div class="sx__event-form-alert sx__event-form-info-box">
                        <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                          <g id="SVGRepo_iconCarrier">
                              <path d="M12 16.75C11.8019 16.7474 11.6126 16.6676 11.4725 16.5275C11.3324 16.3874 11.2526 16.1981 11.25 16V11C11.25 10.8011 11.329 10.6103 11.4697 10.4697C11.6103 10.329 11.8011 10.25 12 10.25C12.1989 10.25 12.3897 10.329 12.5303 10.4697C12.671 10.6103 12.75 10.8011 12.75 11V16C12.7474 16.1981 12.6676 16.3874 12.5275 16.5275C12.3874 16.6676 12.1981 16.7474 12 16.75Z" fill="#0077cc"></path>
                              <path d="M12 9.25C11.8019 9.24741 11.6126 9.16756 11.4725 9.02747C11.3324 8.88737 11.2526 8.69811 11.25 8.5V8C11.25 7.80109 11.329 7.61032 11.4697 7.46967C11.6103 7.32902 11.8011 7.25 12 7.25C12.1989 7.25 12.3897 7.32902 12.5303 7.46967C12.671 7.61032 12.75 7.80109 12.75 8V8.5C12.7474 8.69811 12.6676 8.88737 12.5275 9.02747C12.3874 9.16756 12.1981 9.24741 12 9.25Z" fill="#0077cc"></path>
                              <path d="M12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89472 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17294C11.99 2.82567 13.7996 3.0039 15.4442 3.68509C17.0887 4.36628 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.387 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21ZM12 4.5C10.5166 4.5 9.0666 4.93987 7.83323 5.76398C6.59986 6.58809 5.63856 7.75943 5.07091 9.12988C4.50325 10.5003 4.35473 12.0083 4.64411 13.4632C4.9335 14.918 5.64781 16.2544 6.6967 17.3033C7.7456 18.3522 9.08197 19.0665 10.5368 19.3559C11.9917 19.6453 13.4997 19.4968 14.8701 18.9291C16.2406 18.3614 17.4119 17.4001 18.236 16.1668C19.0601 14.9334 19.5 13.4834 19.5 12C19.5 10.0109 18.7098 8.10323 17.3033 6.6967C15.8968 5.29018 13.9891 4.5 12 4.5Z" fill="#0077cc"></path>
                          </g>
                        </svg>
                        This event is in a hidden calendar.
                    </div>
                    <div class="sx__form-field">
                        <div class="sx__text-field-wrapper"><input id="sazzdx99x8" name="title" type="text" class="sx__text-field-input"><label for="title" class="sx__text-field-label">Title</label></div>
                    </div>
                    <div class="sx__event-time-input">
                        <div class="sx__date-time-picker sx__date-time-start">
                          <div id="sb0g5ayxzn" class="sx__date-picker-outer-wrapper">
                              <div class="sx__date-picker-wrapper">
                                <div id="snt4h205rz" class="sx__date-input-wrapper"><label for="svqp7wyz0i" id="shmge7026q" class="sx__date-input-label">Start date</label><input id="svqp7wyz0i" tabindex="0" name="start-date" aria-describedby="shmge7026q" data-testid="date-picker-input" type="text" class="sx__date-input"><button tabindex="0" aria-label="Choose Date" class="sx__date-input-chevron-wrapper"><img src="data:image/svg+xml,%3c%3fxml version='1.0' encoding='utf-8'%3f%3e%3c!-- Uploaded to: SVG Repo%2c www.svgrepo.com%2c Generator: SVG Repo Mixer Tools --%3e%3csvg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6 9L12 15L18 9' stroke='%23DED8E1' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" alt="" class="sx__date-input-chevron"></button></div>
                              </div>
                          </div>
                          <div id="s10n76nw14">
                              <div class="sx__time-picker-wrapper  ">
                                <div id="smzymv2f37" class="sx__time-input-wrapper"><label for="syur0dsg4s" class="sx__time-input-label"></label><input readonly="" id="syur0dsg4s" name="start-time" type="text" class="sx__time-picker-input"></div>
                              </div>
                          </div>
                        </div>
                        <span class="sx__date-time-until">until</span>
                        <div class="sx__date-time-picker sx__date-time-end">
                          <div id="sprxmng2ln" class="sx__date-picker-outer-wrapper">
                              <div class="sx__date-picker-wrapper">
                                <div id="si0420gwwp" class="sx__date-input-wrapper"><label for="s2bh8noeui" id="s9e1ve82kz" class="sx__date-input-label">End date</label><input id="s2bh8noeui" tabindex="0" name="end-date" aria-describedby="s9e1ve82kz" data-testid="date-picker-input" type="text" class="sx__date-input"><button tabindex="0" aria-label="Choose Date" class="sx__date-input-chevron-wrapper"><img src="data:image/svg+xml,%3c%3fxml version='1.0' encoding='utf-8'%3f%3e%3c!-- Uploaded to: SVG Repo%2c www.svgrepo.com%2c Generator: SVG Repo Mixer Tools --%3e%3csvg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6 9L12 15L18 9' stroke='%23DED8E1' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" alt="" class="sx__date-input-chevron"></button></div>
                              </div>
                          </div>
                          <div id="sj39bix2ww">
                              <div class="sx__time-picker-wrapper  ">
                                <div id="s6x8e7edcm" class="sx__time-input-wrapper"><label for="syci7d56t3" class="sx__time-input-label"></label><input readonly="" id="syci7d56t3" name="end-time" type="text" class="sx__time-picker-input"></div>
                              </div>
                          </div>
                        </div>
                    </div>
                    <label id="sz3ybh16wp" class="sx__app-checkbox"><input type="checkbox"><span>Full-day event</span></label>
                    <div class="sx__rrule-wrapper">
                        <div class="sx__form-field">
                          <div id="synloohgl4" class="sx__app-select-wrapper">
                              <div class="sx__app-select__input-wrapper"><input id="s22z3m8ril" type="text" readonly="" name="rrule" class="sx__app-select-input"><label for="rrule" class="sx__app-select-label">Frequency</label></div>
                          </div>
                        </div>
                        <div id="szcgbxqje9" class="sx__date-picker-outer-wrapper">
                          <div class="sx__date-picker-wrapper has-full-width is-disabled">
                              <div id="s4s99kvxhy" class="sx__date-input-wrapper"><label for="s92ma0xm8q" id="se7is5sj4s" class="sx__date-input-label">Until</label><input id="s92ma0xm8q" tabindex="-1" name="until" aria-describedby="se7is5sj4s" data-testid="date-picker-input" type="text" class="sx__date-input"><button tabindex="-1" aria-label="Choose Date" class="sx__date-input-chevron-wrapper"><img src="data:image/svg+xml,%3c%3fxml version='1.0' encoding='utf-8'%3f%3e%3c!-- Uploaded to: SVG Repo%2c www.svgrepo.com%2c Generator: SVG Repo Mixer Tools --%3e%3csvg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6 9L12 15L18 9' stroke='%23DED8E1' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" alt="" class="sx__date-input-chevron"></button></div>
                          </div>
                        </div>
                    </div>
                    <div class="bg-customer-reply p-3 pt-0">
                       <div class="nested-panel-heading skill-heading"><h3 class="nested-title">Recurrence Settings</h3></div>
                       <div class="form-group d-flex repeat-row">
                          <span class="fw-bold">Repeat every</span> <input type="number" step="1" max="10" value="1"  class="form-control repeat-input-70">
                          <select class="form-control repeat-input-120">
                              <option>Weeks</option>
                          </select>
                        </div>
                        <div class="form-group">
                          <div class="form-lable mb-3 fw-bold">Repeat On</div>
                          <div class="day-selection d-flex">
                              <button class="btn btn-days">M</button>
                              <button class="btn btn-days selected">T</button>
                              <button class="btn btn-days">W</button>
                              <button class="btn btn-days selected">T</button>
                              <button class="btn btn-days">F</button>
                              <button class="btn btn-days">S</button>
                              <button class="btn btn-days">S</button>
                          </div>
                        </div>
                         
                        <div class="form-group">
                            <div class="form-lable mb-3 fw-bold">Ends</div>
                            <div class="form-check pb-4">
                                <label class="form-check-label" for="flexRadioDefault2">
                                  Never 
                                </label>
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
                            </div>
                            <div class="form-check d-flex align-items-center pb-4 col-gap-12">
                              <div class="repeat-input-70">
                                <label class="form-check-label" for="flexRadioDefault1">
                                  On
                                </label>
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                              </div>
                              <div>
                                 <input type="text" class="form-control">
                               </div>
                            </div>
                           
                            <div class="form-check d-flex align-items-center col-gap-12">
                              <div class="repeat-input-70">
                                <label class="form-check-label" for="flexRadioDefault1">
                                  After
                                </label>
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                              </div>
                              <div class="d-flex align-items-center">
                                 <input type="number" step="1" max="10" value="1" class="form-control repeat-input-70"> Occurance
                               </div>
                            </div>

                        </div>  
                        
                    
                    </div>
                    <div class="sx__form-field">
                        <div class="sx__textarea-wrapper"><textarea id="last_name" type="text" name="description" class="sx__textarea-input" style="height: 85px;"></textarea><label for="description" class="sx__textarea-label">Description</label></div>
                    </div>
                    <div class="sx__form-field">
                        <div id="sf0f20t693" class="sx__app-combobox-wrapper">
                          <div class="sx__app-combobox__input-wrapper"><input id="shf5d9nku5" type="text" placeholder="Search..." name="people" class="sx__app-combobox-input"><label for="people" class="sx__app-combobox-label">People</label></div>
                        </div>
                    </div>
                    <div class="sx__form-field">
                        <div id="swnepfib1r" class="sx__app-combobox-wrapper">
                          <div class="sx__app-combobox__input-wrapper"><input id="s9kjl8sqel" type="text" placeholder="Search..." name="calendar" class="sx__app-combobox-input"><label for="calendar" class="sx__app-combobox-label">Calendar</label></div>
                        </div>
                    </div>
                    <div class="sx__form-field">
                        <div id="s8hpd89n8d" class="sx__app-combobox-wrapper">
                          <div class="sx__app-combobox__input-wrapper"><input id="sfew442kkv" type="text" placeholder="Search..." name="resource" class="sx__app-combobox-input"><label for="resource" class="sx__app-combobox-label">Resource</label></div>
                        </div>
                    </div>
                    <div class="sx__form-actions"><button class="sx__app-button sx__app-button-filled">Save</button><button class="sx__app-button sx__app-button-outlined">Cancel</button></div>
                  </div>
                </div>  
            </div>
        </div>
    </div>
   
 </template>
 <style src="@vueform/toggle/themes/default.css"></style>
 <script>
     import * as Vue from 'vue'
     import Toggle from '@vueform/toggle'
     import Multiselect from '@vueform/multiselect'
     import iServiceSwitch from '../components/iServiceSwitch.vue';
     import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';
     import MyEstimateEmailReminderPreview from '../components/MyEstimateEmailReminderPreview.vue';
     import EstimateQuotePreview from '../components/EstimateQuotePreview.vue';
     import MyEstimateEstimater from '../components/MyEstimateEstimater.vue';
     import EmailVisitNotice from '../components/EmailVisitNotice.vue';
     import { ScheduleXCalendar } from '@schedule-x/vue'
     import CalendarMessageTableList from '../components/CalendarMessageTableList.vue';
     
     var iservice = window.iservice || {};

     iservice.date = {};
  ((date) => {
    function isValid(date) {
      if (!date) return false;
      if (!(date instanceof Date)) return false;
      return date.toString() != 'Invalid Date';
    }
    function makeDate(obj) {
      if (obj instanceof Date) return obj;
      if (typeof obj == 'string' || obj instanceof String) {
        return new Date(obj);
      }
      return null;
    }
    function utcYYYY(date) { return `${date.getUTCFullYear()}`; }
    function utcMM(date) { var month = `${date.getUTCMonth() + 1}`; return month.length < 2 ? `0${month}` : month; }
    function utcDD(date) { var day = `${date.getUTCDate()}`; return day.length < 2 ? `0${day}` : day; }
    function localYYYY(date) { return `${date.getFullYear()}`; }
    function localMM(date) { var month = `${date.getMonth() + 1}`; return month.length < 2 ? `0${month}` : month; }
    function localM(date) { return `${date.getMonth() + 1}`; }
    function localDD(date) { var day = `${date.getDate()}`; return day.length < 2 ? `0${day}` : day; }
    function localHH(date) { var hours = `${date.getHours()}`; return hours.length < 2 ? `0${hours}` : hours; }
    function localmm(date) { var minute = `${date.getMinutes()}`; return minute.length < 2 ? `0${minute}` : minute; }
    function localss(date) { var seconds = `${date.getSeconds()}`; return seconds.length < 2 ? `0${seconds}` : seconds; }
    function localms(date) { var ms = `${dateObj.getMilliseconds()}`; return ms.length < 2 ? `00${ms}` : (ms.length < 3 ? `0${ms}` : ms); }

    date.apiDateIn = function (utcStr) {
      var utc = new Date(utcStr);
      if (!isValid(utc)) return null;
      var local = new Date();
      local.setFullYear(utc.getUTCFullYear(), utc.getUTCMonth(), utc.getUTCDate());
      return local;
    }
    date.apiDateOut = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      return `${iservice.date.localYYYYMMDD(date)}T00:00:00.000Z`
    }
    date.apiDateExport = function (utcStr) { //export to excel. directly output the utc date sent from api.
      var utc = new Date(utcStr);
      if (!isValid(utc)) return null;
      return utc;
    }
    date.apiDatetimeIn = function (utcStr) {
      var utc = new Date(utcStr);
      if (!isValid(utc)) return null;
      return utc;
    }
    date.apiDatetimeOut = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      return date.toISOString();
    }
    date.apiDatetimeExport = function (utcStr) { //export to excel. take utc from api, get local values and store in utc time zone.
      var local = new Date(utcStr);
      if (!isValid(local)) return null;
      var utc = new Date();
      utc.setUTCFullYear(local.getFullYear(), local.getMonth(), local.getDate());
      utc.setUTCHours(local.getHours(), local.getMinutes(), local.getSeconds(), local.getMilliseconds());
      return utc;
    }
    date.utcYYYYMMDD = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      return `${utcYYYY(date)}-${utcMM(date)}-${utcDD(date)}`;
    }
    date.localMDD = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      return `${localM(date)}/${localDD(date)}`;
    }
    date.localYYYYMMDD = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      return `${localYYYY(date)}-${localMM(date)}-${localDD(date)}`;
    }
    date.localYYYYMMDDHmm = function (date, minute) {
      if (!isValid(date = makeDate(date))) return '';
      if (minute) date.setMinutes(date.getMinutes() + minute);
      var hours = date.getHours();
      hours = hours < 10 ? `0${hours}` : `${hours}`;
      var minutes = date.getMinutes();
      minutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      return `${localYYYY(date)}-${localMM(date)}-${localDD(date)} ${hours}:${minutes}`;
    }
    date.localYYYYMMDDHmmA = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      var hours = date.getHours();
      var hour = hours == 0 ? '12' : (hours < 13 ? `${hours}` : `${hours - 12}`);
      var ampm = hours < 12 ? 'AM' : 'PM';
      return `${localYYYY(date)}-${localMM(date)}-${localDD(date)} ${hour}:${localmm(date)} ${ampm}`;
    }
    date.localYYYYMMDDHmmssA = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      var hours = date.getHours();
      var hour = hours == 0 ? '12' : (hours < 13 ? `${hours}` : `${hours - 12}`);
      var ampm = hours < 12 ? 'AM' : 'PM';
      return `${localYYYY(date)}-${localMM(date)}-${localDD(date)} ${hour}:${localmm(date)}:${localss(date)} ${ampm}`;
    }
    date.localYYYY = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      return `${localYYYY(date)}`;
    }
    date.localHmmA = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      var hours = date.getHours();
      var hour = hours == 0 ? '12' : (hours < 13 ? `${hours}` : `${hours - 12}`);
      var ampm = hours < 12 ? 'AM' : 'PM';
      return `${hour}:${localmm(date)} ${ampm}`;
    }
  })(iservice.date);
  var feature = Vue.reactive({ isCalendar: true, preFixId: "", postFixId: "", selectedDate: "", events: [], errors: [], sucess: null, calendars: {}, event: {} });
    import {
    createCalendar,
    viewMonthAgenda,
    viewMonthGrid,
    viewWeek
    } from '@schedule-x/calendar'
    import '@schedule-x/theme-default/dist/index.css'
    import {createEventRecurrencePlugin, createEventsServicePlugin} from "@schedule-x/event-recurrence";
    import { CalendarEvent } from '@schedule-x/calendar'
    import { createResizePlugin } from '@schedule-x/resize'
    import {createEventModalPlugin} from "@schedule-x/event-modal";
    import {createDragAndDropPlugin} from "@schedule-x/drag-and-drop";
    import {createScrollControllerPlugin} from "@schedule-x/scroll-controller";
    import { createSidebarPlugin } from "@sx-premium/sidebar";
    import { createInteractiveEventModal } from "@sx-premium/interactive-event-modal";
   
    import '@sx-premium/sidebar/dist/index.css'
    import '@sx-premium/interactive-event-modal/dist/index.css'

     import { ref, watchEffect } from "vue"
     export default {
       name: 'My Estimate calendar',
       props: ['prop'],
       emits: ['onAction'],
        components: {
          Multiselect,
          iServiceSwitch,
          Toggle,
          VariableAndStockResponses,
          MyEstimateEmailReminderPreview,
          EstimateQuotePreview,
          EmailVisitNotice,
          ScheduleXCalendar,
            createCalendar,
            viewMonthAgenda,
            viewMonthGrid,
            viewWeek,
            createEventRecurrencePlugin, createEventsServicePlugin,
            //CalendarEvent,
            createResizePlugin,
            createEventModalPlugin,
            createDragAndDropPlugin,
            createScrollControllerPlugin,
            createSidebarPlugin,
            createInteractiveEventModal,
            CalendarMessageTableList
            
       },
       data: function () {
         return {
            allowmultiple : false,
            customerviewable : false,
            schedule : true,
            reminder: false,
            completeInspection: false,
            sendQuote  : false,
            approval : false,
            startJob:false,
            step : 'schedule',
            assignestimate : false,
            rejectMessage : false,
            acceptMessage : false,
            estimatetab : true,
            jobtab : false,
            rejectMessage1 : false,
            acceptMessage1 : false,
            showCalendar : true

         }
       },
       events: {
        
       },
       mounted() { 
            
        },
       methods: {
         showStep(stepdata){
            this.step = stepdata;
         },
         showModal() {
            document.getElementById("event-details-popup").click(); 
        },
        toggleCalendar(){
            this.showCalendar = !this.showCalendar;
        }
       },
       setup(props,context){
        
        const eventsService = createEventsServicePlugin();
        const interactiveEventModal = createInteractiveEventModal({
        eventsService,
        availablePeople: ['John Doe', 'Jane Doe'],
        onAddEvent: (event) => {
            console.log('Event added', event)
        }
        });
        const sidebar = createSidebarPlugin({
            eventsService,
            
            // Optional: Should the sidebar be open on render
            openOnRender: false,
            
            // Optional: Which calendars should be active on render
            activeCalendarIds: ['personal', 'work', 'leisure', 'school'],
            
            // Optional: Should there be calendar toggles
            hasCalendarToggles: true,
            
            // Optional: placeholder events for drag-to-create
            placeholderEvents: [
                {
                title: 'Morning brief',
                calendarId: 'internal',
                people: ['John Doe', 'Jane Doe', 'Steve Smith'],
                },
                {
                title: 'Client demo',
                calendarId: 'internal',
                people: ['John Doe', 'Jane Doe'],
                },
                {
                title: 'Team meeting',
                calendarId: 'clients',
                people: ['John Doe', 'Jane Doe', 'Steve Smith'],
                }
            ],
            
            // Optional: factory function for generating event ids when events are created
            idFactory: () => uuidv4() // or any other id generator
            })
        
        let isSelecting = false;
        let selectionStart = null;
        let selectionEnd = null;
        let selectionOverlay = null;
        Vue.watchEffect(() => {
            document.addEventListener('mousedown', function (event) {
            if (event.target && event.target.classList && event.target.classList.length && event.target.classList[0] == 'sx__time-grid-day') {
                selectionStart = null;
                selectionEnd = null;
                isSelecting = true;
                selectionStart = getDateAtEvent(event);
                // Create and style the selection overlay
                selectionOverlay = document.createElement('div');
                selectionOverlay.classList.add('selection-overlay');
                
                // Initialize the overlay position and size
                updateOverlay(event);
            }
            });
            document.addEventListener('mousemove', function (event) {
            if (isSelecting) {
                if ((event.offsetY % 15) > 0) return;
                let selectElement = event.target?.getElementsByClassName('overlay-start-time')
                if (selectElement.length == 0) event.target.appendChild(selectionOverlay);

                let startTime = event.target?.getElementsByClassName('overlay-start-time');
                if (startTime.length > 0) {
                startTime[0].innerHTML = selectionStart.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                } else {
                startTime = document.createElement('span');
                startTime.classList.add('overlay-start-time');
                startTime.innerHTML = selectionStart.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                selectionOverlay.appendChild(startTime);
                }

                selectionEnd = getDateAtEvent(event);
                let start = null;
                let end = null;
                startTime = event.target?.getElementsByClassName('overlay-start-time');
                start = new Date(selectionStart.date);
                start.setHours(0, 0, 0, 0);
                end = new Date(selectionEnd.date);
                end.setHours(0, 0, 0, 0);
                if (selectionStart.clientY < event.offsetY) {
                start.setMinutes(selectionStart.clientY);
                end.setMinutes(event.offsetY);
                } else {
                start.setMinutes(event.offsetY);
                end.setMinutes(selectionStart.clientY);
                }
                selectionStart.date = start;
                selectionEnd.date = end;
                startTime[0].innerHTML = start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                let endTime = document.getElementsByClassName('overlay-end-time');
                if (endTime.length > 0) {
                endTime[0].innerHTML = end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                } else {
                endTime = document.createElement('span');
                endTime.classList.add('overlay-end-time');
                endTime.innerHTML = selectionEnd.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                selectionOverlay.appendChild(endTime);
                }
                updateOverlay(event); // Update overlay position and size
            }
            });
            document.addEventListener('mouseup', function (event) {
            if (isSelecting) {
                isSelecting = false;
                if (!selectionEnd) return;
                let start = null;
                let end = null;
                start = new Date(selectionStart.date);
                start.setHours(0, 0, 0, 0);
                end = new Date(selectionEnd.date);
                end.setHours(0, 0, 0, 0);
                if (selectionStart.clientY < selectionEnd.clientY) {
                start.setMinutes(selectionStart.clientY);
                end.setMinutes(selectionEnd.clientY);
                } else {
                start.setMinutes(selectionEnd.clientY);
                end.setMinutes(selectionStart.clientY);
                }
                selectionStart.date = start;
                selectionEnd.date = end;
                if (selectionStart && selectionEnd) {
                feature.title = "";
                if (props.config?.startProperty && allValues[props.config?.startProperty]) {
                    allValues[props.config?.startProperty].Details([{ valueDatetimeUtc: iservice.date.apiDatetimeOut(selectionStart.date) }]);
                }
                if (props.config?.endProperty && allValues[props.config?.endProperty]) {
                    allValues[props.config?.endProperty].Details([{ valueDatetimeUtc: iservice.date.apiDatetimeOut(selectionEnd.date) }]);
                }
                feature.isCreateEventForm = true;
                }
                document.getElementById("event-details-popup").click(); 
            }
            });
            function updateOverlay(event) {
             
            const startY = Math.min(event.offsetY, selectionStart.clientY);
            const endY = Math.max(event.offsetY, selectionStart.clientY);

            //selectionOverlay.style.left = `${startX}px`;
            selectionOverlay.style.top = `${startY}px`;
            selectionOverlay.style.width = `100%`;
            selectionOverlay.style.height = `${endY - startY}px`;
            }
            function getDateAtEvent(event) {
            let eventY = (parseInt(JSON.parse(JSON.stringify(event.offsetY)) / 15)) * 15;
            
            const date = new Date(iservice.date.apiDateIn(event.target.dataset.timeGridDate).setHours(0, 0, 0, 0));
          
            //date.setMinutes(eventY);
            // Include client coordinates in the returned object for overlay positioning
            return {
                date: date,
                clientX: event.offsetX,
                clientY: eventY
            };
            }
        }); 
        const calendarApp = createCalendar({
        selectedDate: '2024-08-22',
        locale: 'en-UK',
        views: [viewMonthAgenda, viewMonthGrid, viewWeek],
        defaultView: viewWeek.name,
        plugins: [
            createEventModalPlugin(),
            createDragAndDropPlugin(30),
            createScrollControllerPlugin(),
            createEventRecurrencePlugin(),
            createResizePlugin(),
            eventsService,
            sidebar,
            interactiveEventModal,
            
        ],
        callbacks: {
            onRangeUpdate(range) {
        console.log('new calendar range start date', range.start)
        console.log('new calendar range end date', range.end)
        },
    
        /**
         * Is called when an event is updated through drag and drop
         * */
        onEventUpdate(updatedEvent) {
        console.log('onEventUpdate', updatedEvent)
        },
    
        /**
        * Is called when an event is clicked
        * */
        onEventClick(calendarEvent) {
        console.log('onEventClick', calendarEvent)
        },
    
        /**
        * Is called when clicking a date in the month grid
        * */
        onClickDate(date) {
        console.log('onClickDate', date) // e.g. 2024-01-01
        },
    
        /**
        * Is called when clicking somewhere in the time grid of a week or day view
        * */
        onClickDateTime(dateTime) {
        console.log('onClickDateTime', dateTime) // e.g. 2024-01-01 12:37
        },
    
        /**
        * Is called when selecting a day in the month agenda
        * */
        onClickAgendaDate(date) {
        console.log('onClickAgendaDate', date) // e.g. 2024-01-01
        },
    
        /**
        * Is called when double clicking a date in the month grid
        * */
        onDoubleClickDate(date) {
        console.log('onClickDate', date) // e.g. 2024-01-01
        },
    
        /**
        * Is called when double clicking somewhere in the time grid of a week or day view
        * */
        onDoubleClickDateTime(dateTime) {
        console.log('onDoubleClickDateTime', dateTime) // e.g. 2024-01-01 12:37
        },
    
        /**
        * Is called when clicking the "+ N events" button of a month grid-day
        * */
        onClickPlusEvents(date) {
        console.log('onClickPlusEvents', date) // e.g. 2024-01-01
        },
    
        /**
        * Is called when the selected date is updated
        * */
        onSelectedDateUpdate(date) {
        console.log('onSelectedDateUpdate', date)
        },
    
            },
        events: [
            {
            id: 1,
            title: 'Estimate request',
            start: '2024-08-22',
            end: '2024-08-22',
            }
        
        ],
        calendars: {
            leisure: {
            colorName: 'leisure',
            lightColors: {
                main: '#1c7df9',
                container: '#d2e7ff',
                onContainer: '#002859',
            },
            darkColors: {
                main: '#c0dfff',
                onContainer: '#dee6ff',
                container: '#426aa2',
            },
            },
            },
                monthGridOptions: {
                    nEventsPerDay: 3,
                }
                })
                return{calendarApp};
        
        },  

       
        
     }
        
 </script>